'use client'
import Image from 'next/image'
import React from 'react';
import Head from 'next/head'
import styles from '@/styles/main.css'
import dynamic from 'next/dynamic';
const metadata = {
	title: "Home",
	description: "Home Page",
	name: 'Tyler Heshmati',
	description: 'Tyler Heshmati is a self taught software engineer based in the San Francisco Bay Area, CA.',
	link: 'https://www.linkedin.com/in/tylerheshmati/',
}
const Age = dynamic(() => import('../components/age.js'), { ssr: false })
export default function Home() {
	return (<>
		<Head>
			<title>{metadata.title}</title>
		</Head>
		<Age />
	</>
	)
}

